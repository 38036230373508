var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"id":"CasinoGroup Component","fluid":"","tag":"section"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-card',{staticClass:"v-card--material true pa-3"},[_c('CardHeading',{attrs:{"loading":_vm.loading,"loadingTitle":`Loading studio groups...`,"color":'secondary',"title":"Studios"}}),_c('v-row',{staticClass:"mb-2 align-center"},[_c('v-col',{staticClass:"pa-0 pl-2",attrs:{"cols":"9","md":"4"}},[_c('v-autocomplete',{key:`${_vm.list.length}`,staticClass:"ml-2 mr-2 mr-md-3",attrs:{"loading":_vm.loading,"eager":"","label":"Groups","item-text":"name","disabled":_vm.loading,"items":_vm.list},on:{"change":_vm.rowClickHandle},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}})],1),_c('v-col',{staticClass:"px-0 pt-0 pb-2 pb-md-4",attrs:{"cols":"3","md":"4"}},[(_vm.list.length > 0)?_c('ConfirmDialog2',{attrs:{"persistent":"","confirmBtnText":'Delete',"confirmBtnColor":'error',"cancelBtnColor":'kajot-text',"cancelBtnText":'Back',"shouldShow":true},on:{"confirm":_vm.deleteItem},scopedSlots:_vm._u([{key:"body",fn:function(){return [_vm._v(" This studio group will be deleted. "),_c('br'),_vm._v(" Do you wish to continue ? ")]},proxy:true},{key:"default",fn:function({ showConfirm }){return [(
										_vm._usrFlagsSome({
											key: 'management.groups.detail',
											val: _vm.permissions.DELETE,
										})
									)?_c('v-btn',{staticClass:"error mr-1 mr-md-3",attrs:{"fab":"","small":!_vm.isMobile,"x-small":_vm.isMobile},on:{"click":showConfirm}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,false,1799215292)}):_vm._e(),(
								_vm._usrFlagsSome({
									key: 'management.groups.detail',
									val: _vm.permissions.CREATE,
								})
							)?_c('v-btn',{staticClass:"primary",attrs:{"fab":"","small":!_vm.isMobile,"x-small":_vm.isMobile},on:{"click":function($event){_vm.createDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),(
							_vm._usrFlagsSome({
								key: 'management.groups.detail',
								val: _vm.permissions.EDIT,
							})
						)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('EditComponent',{key:`${_vm.studios.length}`,ref:"editComponent",attrs:{"fullArray":_vm.studios,"selectedArray":_vm.groupStudios,"loading":_vm.loading,"noDialog":"","noModel":"","maxChips":3,"valueKey":"name","labelKey":"name","width":200,"title":"Edit Studio List","placeholderText":"Studios","color":"primary"},on:{"submitEditComponent":_vm.editGroup},scopedSlots:_vm._u([{key:"appendItem",fn:function({ item }){return [_c('v-chip',[_vm._v("ID: "+_vm._s(item.id))])]}}],null,false,650707616)})],1):_vm._e()],1),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"image@2"}}):_c('div',{staticClass:"d-flex justify-center"},[_c('v-row',{staticClass:"d-flex",attrs:{"dense":""}},_vm._l((_vm.groupStudios),function(item){return _c('v-col',{key:item.name,staticClass:"pb-2",attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('v-card',{staticClass:"elevation-1 ma-0"},[_c('v-card-title',{staticClass:"secondary d-flex align-center justify-space-between py-2 body-1"},[_vm._v(" "+_vm._s(item.name.toUpperCase())+" "),_c('v-chip',[_vm._v("ID: "+_vm._s(item.id))])],1)],1)],1)}),1)],1),(
						_vm._usrFlagsSome({
							key: 'management.groups.detail',
							val: _vm.permissions.EDIT,
						})
					)?_c('v-card-actions',{staticClass:"stickyButtons"},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.disableSubmit,"text":""},on:{"click":function($event){return _vm.$refs.editComponent.reset()}}},[_c('v-icon',{staticClass:"primary--text mr-1",attrs:{"fab":"","small":""}},[_vm._v(" mdi-restore ")]),_vm._v(" Reset ")],1),_c('v-btn',{attrs:{"disabled":_vm.disableSubmit,"text":""},on:{"click":function($event){return _vm.$refs.editComponent.submit()}}},[_vm._v(" Submit ")])],1):_vm._e()],1)],1),_c('v-dialog',{staticClass:"col-6 ml-auto mr-auto",attrs:{"width":"40%"},model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('v-card',[_c('v-card-title',{class:'headline primary py-3 mb-3'},[_vm._v(" Create Permission Group ")]),_c('v-card-text',[_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.validEdit),callback:function ($$v) {_vm.validEdit=$$v},expression:"validEdit"}},[_c('v-text-field',{attrs:{"autofocus":"","rules":[
									_vm.rules.required,
									_vm.rules.minMaxLength(1, 40),
									_vm.noSameName,
								],"label":"Name"},model:{value:(_vm.textField),callback:function ($$v) {_vm.textField=$$v},expression:"textField"}}),_c('EditComponent',{ref:"createComponent",attrs:{"fullArray":_vm.studios,"selectedArray":_vm.newStudios,"noDialog":"","noModel":"","loading":false,"valueKey":"name","labelKey":"name","width":500,"title":"Edit Studio List","placeholderText":"Studios","color":"primary"},on:{"submitEditComponent":_vm.createGroup},scopedSlots:_vm._u([{key:"appendItem",fn:function({ item }){return [_c('v-chip',[_vm._v("ID: "+_vm._s(item.id))])]}},{key:"actions-wrapper",fn:function({ item }){return [_c('v-chip',[_vm._v("ID: "+_vm._s(item.id))])]}}])})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.createDialog = false}}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.validEdit,"color":"primary","text":""},on:{"click":_vm.unholyCrap}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }